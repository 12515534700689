@import url('https://fonts.googleapis.com/css2?family=Poppins&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Lato&display=swap');
@import 'bootstrap-icons/font/bootstrap-icons.css';
@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  overflow-x: hidden;
  height: 100vh;
  width: 100vw;
}

html {
  height: 100vh;
  width: 100vw;
}

::-webkit-scrollbar {
  width: 12px;
}

::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  border-radius: 10px;
}

::-webkit-scrollbar-thumb {
  border-radius: 10px;
  background-color: #afdef957;
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.5);
  box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
}

.custom-pointer:hover {
  cursor: pointer !important;
}

.custom-link {
  box-shadow: inset 0 0 0 0 #1b2c98 !important;
  color: black !important;
  transition: color .3s ease-in-out, box-shadow .3s ease-in-out !important;
}

.custom-link:hover {
  box-shadow: inset 200px 0 0 0 #1b2c98 !important;
  padding: 0px 4px 0px 4px !important;
  border-radius: 5px;
  color: white !important;
}

/* Buttons------------------------------------------------------------------------------------------------------------*/
.custom-btn {
  @apply w-32 h-10 rounded-md
}

.custom-btn-large {
  @apply w-48 h-16 rounded-md bg-blue
}

.custom-primary-btn {
  @apply bg-primary hover:bg-white hover:text-primary text-white border border-primary
}

.custom-secondary-btn {
  @apply bg-white hover:bg-primary text-primary hover:text-white border border-primary
}

.custom-primary-section-btn {
  @apply w-36 h-12 rounded bg-white font-poppins text-base font-medium text-primary90 hover:bg-primary hover:text-white hover:border border-white
}

.custom-secondary-section-btn {
  @apply w-36 h-12 rounded bg-primary font-poppins text-base font-medium text-white border border-white hover:bg-white hover:text-primary90
}

/* cards------------------------------------------------------------------------------------------------------------*/
.testimonial-card {
  @apply bg-white rounded drop-shadow-lg w-72 min-h-[420px]
}

/* inputs------------------------------------------------------------------------------------------------------------*/
.form-input-fields {
  @apply w-full h-12 rounded indent-1.5
}

.textarea-input-fields {
  @apply w-full h-24 rounded indent-1.5
}

/* Picture--------------------------------------------------------------------------------------------------------- */

li.list-checkmark {
  list-style-type: none;
  background-image: url(https://storage.googleapis.com/studyitlive_website_images/Website_Images/checkMark.svg);
  background-position: 0.1rem;
  background-repeat: no-repeat;
  padding-left: 2rem;
  border: none;
}


.bg-home-image {
  background-image: url(https://storage.googleapis.com/studyitlive_website_images/Website_Images/joinBackground.png);
}

.profile-picture {
  width: 103px !important;
  height: 100px !important;
}

/* Sizing -----------------------------------------------------------------------------------------------------------*/

.custom-table-cell-width {
  width: 10% !important;
}

.page-card-container {
  min-height: 300px;
  height: 83vh !important;
  overflow: auto;

}

.mobile-sidenav {
  width: 20vw !important;
  min-width: 300px;
}

/* Color----------------------------------------------------------------------------------------------------------------- */

.light-bg-grey {
  background-color: #F5F9FA !important;
}

.bg-green {
  background: #72df70cc !important;
}

.blue-color {
  color: #2f46da !important;
}

.dark-blue-color {
  color: #1b2c98 !important;
}

.blumine-color {
  color: #1F5372 !important;
}

.navy-blue-color {
  color: #103449 !important;
}

.bg-red {
  background: #fff8f8 !important;
}

.bg-dblue {
  background: #1b2c98 !important;
}

.bg-purple {
  background: #5b5583 !important;
  color: #fff !important;
}

.light-bg-green {
  background: #d3ffd2 !important;
}

.bg-grey {
  background-color: #f2f1f1 !important;
}

.bg-blue {
  background: #afdef9 !important;
}

li button {
  padding: 1rem 1rem !important;
  border-radius: 0;
}

.filepond--panel-root {
  background-color: #fff !important;
  border: 1px solid rgb(100 116 139);
}
